<template>
  <nav class="w-100 bg-primary menubar">
    <ul>
      <li>
        <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: slug } }" exact :class="{ 'router-link-active': menuPrimaryUser === 'home' }">{{$t('home')}}</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'PlayerAwards', params: { lang: lang, console: console, playerSlug: slug } }" exact>{{$tc('award', 2) | toCapitalize}}</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'PlayerRecord', params: { lang: lang, console: console, playerSlug: slug } }" exact>{{$t('record')}}</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'PlayerLastMatches', params: { lang: lang, console: console, playerSlug: slug } }" exact>{{$t('lastMatches')}}</router-link>
      </li>
    </ul>
    <ul v-if="menuPrimaryUser === 'home'">
      <li>
        <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: slug } }" exact>{{$t('stats')}}</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'PlayerNotices', params: { lang: lang, console: console, playerSlug: slug } }" exact>{{$t('notices')}}</router-link>
      </li>
      <li v-if="isAuthenticated">
        <router-link :to="{ name: 'PlayerMessage', params: { lang: lang, console: console, playerSlug: slug } }" exact>{{$t('message')}}</router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      slug: this.$route.params.playerSlug
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'menuPrimaryUser',
      'playerFront',
      'isAuthenticated'
    ])
  }
}
</script>
