<template>
	<div class="w-100">
		<div class="container-fluid">
			<div class="row header-section player-section" v-if="playerFront">
				<img
					v-if="playerFront.bg"
					slot="img"
					class="w-100 header-section-bg"
					:src="playerFront.bg.image"
					:srcset="
						`${playerFront.bg.image_sm} 600w, ${
							playerFront.bg.image_md
						} 1024w, ${playerFront.bg.image} 2000w`
					"
				/>
				<div class="row-user">
					<div class="col-avatar">
						<div class="player-card pc" :class="[isPremium, cardYear]">
							<img
								:src="playerFront.card"
								:alt="`Tarjeta ${playerFront.nick}`"
								class="img-card"
							/>
							<div class="player-card-left">
								<span class="overall border-text-black">{{
									playerFront.overall
								}}</span>
								<span class="position border-text-black">{{
									playerFront.position
								}}</span>
								<img
									:src="playerFront.country_flag"
									:alt="`Pais de ${playerFront.nick}`"
									class="img-country"
								/>
							</div>
							<div class="player-card-right">
								<img
									:src="playerFront.avatar"
									:alt="`Avatar de ${playerFront.nick}`"
									class="img-avatar"
								/>
							</div>
							<h2 class="player-card-user border-text-black">
								{{ playerFront.nick }}
							</h2>
							<div class="player-card-chemstyle">
								<img
									:src="playerFront.chem_style_image"
									:alt="`Chemstyle de ${playerFront.nick}`"
								/>
							</div>
							<div v-if="playerFront.premium === 1" class="stats">
								<div>
									<span>{{ playerFront.pjs }}</span>
									<img
										src="../../../assets/images/played-b.png"
										alt="Partidos jugados"
									/>
								</div>
								<div>
									<span>{{ playerFront.goals }}</span>
									<img src="../../../assets/images/goals-b.png" alt="Goles" />
								</div>
								<div>
									<span>{{ playerFront.assists }}</span>
									<img
										src="../../../assets/images/assist-b.png"
										alt="Asistencias"
									/>
								</div>
								<div>
									<span>{{ playerFront.bests }}</span>
									<img
										src="../../../assets/images/best-b.png"
										alt="Mejor jugador"
									/>
								</div>
								<div>
									<span>{{ playerFront.yellow_cards }}</span>
									<img
										src="../../../assets/images/yellow.png"
										alt="Tarjetas amarillas"
									/>
								</div>
								<div>
									<span>{{ playerFront.red_cards }}</span>
									<img
										src="../../../assets/images/red.png"
										alt="Tarjetas rojas"
									/>
								</div>
							</div>
						</div>
						<b-button class="hide-in-pc mt-3 mx-auto" @click="seeCard()">{{
							$t('seeCard')
						}}</b-button>
					</div>
					<div class="col-info position-relative">
						<p>
							<strong>ID# {{ playerFront.id }}</strong>
						</p>
						<h5>{{ playerFront.username }}</h5>
						<hr />
						<p>{{ playerFront.motto }}</p>
						<b-button
							class="btn-icon btn-followers"
							variant="outline-primary"
							:disabled="!isAuthenticated"
							@click="addToFavourites()"
						>
							<span class="mr-2">{{ playerFront.followers }}</span>
							<i class="fa fa-star"></i>
						</b-button>
						<div class="player-details mt-3 text-center">
							<div class="awards d-flex">
								<router-link
									:to="{
										name: 'PlayerAwards',
										params: { lang: lang, console: console, playerSlug: slug },
									}"
									class="award text-center"
									v-for="(award, ind) in playerFront.awards"
									:key="`award-${ind}`"
								>
									<img
										:src="award.image_award"
										:alt="award.reason"
										class="img-fluid"
									/>
									<h6 class="mb-0">x{{ award.total }}</h6>
								</router-link>
							</div>
							<div class="teams">
								<h6>{{ $t('teams') }}</h6>
								<router-link
									v-for="(team, ind) in playerFront.teams"
									:key="`router-${ind}`"
									:to="{
										name: 'TeamHome',
										params: { lang: lang, console: console, teamSlug: team.slug },
									}"
								>
									<img :src="team.image" :alt="team.name" class="img-fluid" />
								</router-link>
							</div>
							<div class="type">
								<h6>{{ $t('type') }}</h6>
								<hr />
								<h6 class="mb-0">{{ playerFront.type | completeUserType }}</h6>
							</div>
						</div>
						<div
							v-if="playerFront.premium"
							class="row justify-content-center mt-3"
						>
							<div class="form-group col-10 col-sm-8 col-md-6 text-center">
								<label for="tournament">{{ $t('tournaments') }}</label>
								<select
									name="tournament"
									id="tournament"
									class="form-control"
									v-model="tournament"
								>
									<option value="">{{ $t('selectOption') }}</option>
									<option
										v-for="(item, ind) in playerFront.tournaments"
										:key="`tou-${ind}`"
										:value="item"
										>{{
											`${item.tournament_name} | ${item.division_name} | ${
												item.season_name
											}`
										}}</option
									>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div class="btns-floats">
					<div class="mb-2">
						<a
							v-if="playerFront.facebook"
							:href="playerFront.facebook"
							class="btn btn-primary btn-icon"
						>
							<i class="fa fa-facebook"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
		<menu-player />
		<div class="col-12">
			<router-view />
		</div>
		<!-- Carrusel de usuarios en linea -->
		<carousel-users-online :show-name="false" />
		<modal-see-card :user="playerFront" />
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import MenuPlayer from '../partials/MenuPlayer'
import CarouselUsersOnline from '../../partials/CarouselUsersOnline.vue'
import ModalSeeCard from '../partials/ModalCard'

export default {
	components: {
		MenuPlayer,
		CarouselUsersOnline,
		ModalSeeCard,
	},
	data() {
		return {
			slug: '*',
			tournament: '',
			cardYear: '',
			isPremium: false,
		}
	},
	computed: {
		...mapGetters([
			'loading',
			'lang',
			'console',
			'playerFront',
			'isAuthenticated',
		]),
	},
	mounted() {
		this.slug = this.$route.params.playerSlug
		if (!this.playerFront.tournament) {
			this.fetchData()
		}
	},
	watch: {
		$route(to) {
			if (to.name === 'PlayerHome') {
				this.fetchData()
			}
		},
		tournament(value) {
			if (value) {
				this.$router.push({
					name: 'PlayerHome',
					params: {
						lang: this.lang,
						console: this.console,
						playerSlug: this.slug,
					},
					query: {
						tournament: value.tournament_id,
						division: value.division_id,
						season: value.season_id,
					},
				})
			} else {
				this.$router.push({
					name: 'PlayerHome',
					params: {
						lang: this.lang,
						console: this.console,
						playerSlug: this.slug,
					},
				})
			}
			this.fetchData()
		},
	},
	methods: {
		addToFavourites() {
			const slug = this.$route.params.playerSlug
			const lang = this.$route.params.lang
			const console = this.$route.params.console
			const path = `auth/${lang}/console/${console}/player/${slug}`
			this.$axios.post(path).then(() => {
				this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
			})
		},
		seeCard() {
			this.$root.$emit('bv::show::modal', 'modalSeeCard')
		},
		fetchData() {
			const lang = this.$route.params.lang
			const console = this.$route.params.console
			const slug = this.$route.params.playerSlug
			const path = `${lang}/console/${console}/player/${slug}`
			const params = {
				tournament: this.$route.query.tournament,
				division: this.$route.query.division,
				season: this.$route.query.season,
			}
			this.$axios.get(path, { params }).then((response) => {
				const data = response.data.data
				this.cardYear = `card-${data.card_year}`
				this.isPremium = data.premium ? 'is-premium' : ''
				const colors = data.positions.map(() => {
					return this.generateRandomColor()
				})
				const datasets = {
					labels: data.positions.map((pos) => {
						return pos.position
					}),
					datasets: [
						{
							label: `${this.$t('stats')} - ${data.nick}`,
							data: data.positions.map((pos) => {
								return pos.total
							}),
							backgroundColor: colors,
							borderColor: colors,
							borderWidth: 2,
						},
					],
				}
				data.chart = datasets
				this.$store.dispatch('SET_PLAYER_FRONT', data)
			})
		},
		generateRandomColor() {
			const x = Math.floor(Math.random() * 256)
			const y = Math.floor(Math.random() * 256)
			const z = Math.floor(Math.random() * 256)
			return `rgb(${x}, ${y}, ${z})`
		},
	},
}
</script>
