<template>
  <b-modal id="modalSeeCard" hide-header centered no-close-on-backdrop scrollable>
    <div class="player-card card-modal" :class="{ 'is-premium': user.premium, 'card-2019': user.card_year === 2019 }">
      <img :src="user.card" :alt="`Tarjeta ${user.nick}`" class="img-card">
      <div class="player-card-left">
        <span class="overall border-text-black">{{ user.overall }}</span>
        <span class="position border-text-black">{{ user.position }}</span>
        <img :src="user.country_flag" :alt="`Pais de ${user.nick}`" class="img-country">
      </div>
      <div class="player-card-right">
        <img :src="user.avatar" :alt="`Avatar de ${user.nick}`" class="img-avatar">
      </div>
      <h2 class="player-card-user border-text-black">{{ user.nick }}</h2>
      <div class="player-card-chemstyle">
        <img :src="user.chem_style_image" :alt="`Chemstyle de ${user.nick}`">
      </div>
      <div v-if="user.premium === 1" class="stats">
        <div>
          <span>{{ user.pjs }}</span>
          <img src="../../../assets/images/played-b.png" alt="Partidos jugados">
        </div>
        <div>
          <span>{{ user.goals }}</span>
          <img src="../../../assets/images/goals-b.png" alt="Goles">
        </div>
        <div>
          <span>{{ user.assists }}</span>
          <img src="../../../assets/images/assist-b.png" alt="Asistencias">
        </div>
        <div>
          <span>{{ user.bests }}</span>
          <img src="../../../assets/images/best-b.png" alt="Mejor jugador">
        </div>
        <div>
          <span>{{ user.yellow_cards }}</span>
          <img src="../../../assets/images/yellow.png" alt="Tarjetas amarillas">
        </div>
        <div>
          <span>{{ user.red_cards }}</span>
          <img src="../../../assets/images/red.png" alt="Tarjetas rojas">
        </div>
      </div>
    </div>
    <template slot="modal-footer">
      <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalSeeCard')">{{ $t('close') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    user: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters(['lang', 'console'])
  }
}
</script>
